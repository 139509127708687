import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CHeroMedia,
  CGalleryImgs,
  CGalleryImgsItem,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'CUISINE',
            sub: '料理',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/dish/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/dish/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '料理',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <p className="c_sectLead">
            世界のVIPをお迎えしてきたサービスとおもてなしがここにはあります。
            <br />
            世界の舞台になったこの場所だからこその、お料理のおもてなしが私たちのホテルブランドです。
          </p>
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/banquet/dish/img_dish.png',
                  alt: '',
                },
                text: (
                  <>
                    パーティ一成功のカギを握るのは、お料理のクオリティ。フランス料理・日本料理・中国料理、それぞれの道を極めたシェフが腕をふるうお料理をお届けします。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/dish/img_dish02.png',
                  alt: '',
                },
                text: (
                  <>
                    お客様の好みや企画に合わせた特別メニューなど、心まで華やぐおもてなしのテーブルを演出いたします。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/dish/img_dish03.png',
                  alt: '',
                },
                text: (
                  <>
                    熟練のプロが高い技術で仕上げた彩り鮮やかなメニューは、パーティーにご参加されるお客様への最高のおもてなし。
                  </>
                ),
              },
            ]}
          />
          <CGalleryImgs>
            <CGalleryImgsItem
              size={463}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish04.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={542}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish05.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish06.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={620}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish07.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={385}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish08.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={542}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish09.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish10.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={463}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish11.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={385}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish12.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={620}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish13.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={463}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish14.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={542}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish15.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish16.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={620}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish17.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CGalleryImgsItem
              size={385}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/dish/img_dish18.png',
                    alt: '',
                  },
                },
              ]}
            />
          </CGalleryImgs>
        </LWrap>
      </section>
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
